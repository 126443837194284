<template>
  <!-- head -->
  <div v-if="theuser.raw_user_meta_data != undefined">
    <div style="position: relative">
      <q-img height="250px" :src="profile.cover">
        <!-- <q-btn
          class="q-ma-md transparent flat absolute-top-right"
          icon="edit"
        /> -->
      </q-img>
      <q-avatar class="myavatar" size="200px">
        <img :src="profile.avatar" />
      </q-avatar>
    </div>
    <h5 class="myname text-center">
      {{ profile.nickname }} - {{ $t(levels[profile.ulevel]) }}
    </h5>
    <p class="q-ma-md text-center">
      {{ $t("wallet") }}:
      <a :href="chainscan + '/address/' + theuser.raw_user_meta_data.wallet">
        {{ theuser.raw_user_meta_data.wallet }}
      </a>
    </p>
    <p v-if="theuser.role != 'supabase_admin'" class="q-ma-md text-center">
      Email:
      <a :href="'mailto:' + theuser.email">
        {{ theuser.email }}
      </a>
    </p>    
    <p class="q-ma-md text-center">
      {{ profile.bio }}
    </p>
    <div class="text-center">
      <a v-if="profile.website" :href="profile.website">
        <q-avatar class="q-ma-sm">
          <img src="@/assets/website.png" size="lg" />
        </q-avatar>
      </a>

      <a
        v-if="profile.facebook"
        :href="'https://www.facebook.com/' + profile.facebook"
      >
        <q-avatar class="q-ma-sm">
          <img src="@/assets/facebook.png" size="lg" />
        </q-avatar>
      </a>
      <a
        v-if="profile.twitter"
        :href="'https://www.twitter.com/' + profile.twitter"
      >
        <q-avatar class="q-ma-sm">
          <img src="@/assets/twitter.png" size="lg" />
        </q-avatar>
      </a>
      <a
        v-if="profile.instagram"
        :href="'https://www.instagram.com/' + profile.instagram"
      >
        <q-avatar class="q-ma-sm">
          <img src="@/assets/instagram.png" size="lg" />
        </q-avatar>
      </a>
      <a v-if="profile.shop_link" :href="profile.shop_link">
        <q-avatar class="q-ma-sm myicon">
          <q-icon name="shopping_cart" class="text-orange" size="lg" />
        </q-avatar>
      </a>
      <a v-if="profile.video_link" :href="profile.video_link">
        <q-avatar class="q-ma-sm myicon">
          <q-icon name="movie" class="text-primary" size="lg" />
        </q-avatar>
      </a>
      <a v-if="profile.blog_link" :href="profile.blog_link">
        <q-avatar class="q-ma-sm myicon">
          <q-icon name="library_books" class="text-teal" size="lg" />
        </q-avatar>
      </a>
    </div>
    <div class="q-pa-md q-mt-lg q-mb-lg text-center">
      <q-btn
        v-if="is_allow_mint"
        size="lg"
        class="q-pl-xl q-pr-xl"
        color="blue"
        @click="check_role"
      >
        {{ $t("create_nft") }}
      </q-btn>
      <q-btn
        v-if="is_allow_mint"
        size="lg"
        class="q-ma-md q-pl-xl q-pr-xl"
        color="red"
        @click="this.$router.push('/mysettings')"
      >
        {{ $t("Edit Profile") }}
      </q-btn>
      <q-btn
        size="lg"
        class="q-ma-md q-pl-xl q-pr-xl"
        color="red"
        @click="this.$router.push('/team/' + profile.id)"
      >
        {{ $t("teams") }}
      </q-btn>
      <div v-if="!user" class="q-pa-md q-mt-lg q-mb-lg text-center">
        <q-btn size="lg" @click="join" class="q-pl-xl q-pr-xl" color="blue">
          {{ $t("join_nft") }}
        </q-btn>
        <q-btn
          color="orange"
          :label="$t('share')"
          size="lg"
          class="q-ma-md q-pl-xl q-pr-xl"
          @click="join"
        />
      </div>
      <div v-else>
        <q-btn
          color="orange"
          :label="$t('share')"
          size="lg"
          class="q-ma-md q-pl-xl q-pr-xl"
          @click="share_to(profile.nickname, profile.bio, share_url)"
        />
        <input hidden type="text" :value="share_url" id="my_share_url" />
      </div>

      <q-btn-dropdown
        size="lg"
        class="q-ma-md q-pl-xl q-pr-xl"
        v-if="user && user.role == 'supabase_admin'"
        color="primary"
        label="SET MEMBERSHIP"
      >
        <q-list>
          <q-item clickable v-close-popup @click="set_level(0)">
            <q-item-section>
              <q-item-label>Free User</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup @click="set_level(1)">
            <q-item-section>
              <q-item-label>Bronze</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-close-popup @click="set_level(2)">
            <q-item-section>
              <q-item-label>Silver</q-item-label>
            </q-item-section>
          </q-item>

          <q-item clickable v-close-popup @click="set_level(3)">
            <q-item-section>
              <q-item-label>Gold</q-item-label>
            </q-item-section>
          </q-item>
          <q-item clickable v-close-popup @click="set_level(4)">
            <q-item-section>
              <q-item-label>Ruby</q-item-label>
            </q-item-section>
          </q-item>
        </q-list>
      </q-btn-dropdown>
    </div>
  </div>

  <!-- NFT List -->
  <div class="q-pa-md row my-list">
    <div
      v-for="k in mycreations"
      :key="k.id"
      class="q-pa-md col-xs-12 col-sm-6 col-md-4 col-lg-3"
    >
      <q-card class="my-card">
                <q-item-label class="absolute-bottom-right bg-transparent q-ma-sm">
          <q-icon class="q-ma-sm" color="red" size="24px" name="favorite" />
          <span class="text-weight-bold">{{ k.likes }}</span>
        </q-item-label>
        <q-img class="my-img q-pa-md" :src="k.data.image" :ratio="1">
          <q-img
            v-if="k.total - k.token_sum <= 0"
            class="absolute-center"
            src="@/assets/soldout.png"
          />
        </q-img>

        <q-card-section>
          <div class="text-h6">{{ k.data.name }}</div>
          <!-- <a
            class="my-link text-body"
            :href="'https://mumbai.polygonscan.com/address/' + k.id"
          >
            {{k.id}}
          </a> -->
          <div class="text-body2">
            {{ pdate(k.created_at) }}
          </div>
          <div class="text-body2">{{ $t("price") }}: ${{ k.data.price }}</div>
          <div class="text-body2">
            {{ $t("total") }}: {{ k.data.count }} {{ $t("remains") }}:
            {{ k.total - k.token_sum > 0 ? k.total - k.token_sum : 0 }}
          </div>
          <q-checkbox
            v-if="is_allow_mint"
            class="q-ml-none"
            @click="set_listing(k)"
            v-model="k.listing"
            :label="$t('listing_for_sale')"
          />
        </q-card-section>

        <q-card-section class="">
          <q-btn color="blue" :label="$t('view')" @click="show_nft(k.id)" />
          <q-btn
            class="q-ml-sm"
            v-if="is_allow_mint"
            color="blue"
            :label="$t('mint')"
            @click="
              prompt = true;
              cur_nft = k;
            "
          />

          <q-btn
            class="q-ml-sm"
            v-if="is_allow_mint"
            color="blue"
            :label="$t('edit')"
            :to="'/edit/' + k.id"
          />
        </q-card-section>
      </q-card>
    </div>
  </div>

  <q-dialog v-model="prompt" persistent>
    <q-card style="min-width: 350px">
      <q-card-section>
        <div class="text-h6">
          Mint Token of "{{ cur_nft.data.name }}" to address (0x)
        </div>
      </q-card-section>

      <q-card-section class="q-pt-none">
        <q-input
          dense
          v-model="address"
          autofocus
          @keyup.enter="prompt = false"
        />
      </q-card-section>

      <q-card-actions align="right">
        <q-btn 
          flat 
          :label="$t('cancel')" 
          v-close-popup 
        />
        <q-btn
          flat
          :label="$t('mint')"
          v-close-popup
          @click="mint_token(cur_nft)"
        />
      </q-card-actions>
    </q-card>
  </q-dialog>
</template>
<style lang="sass" scoped>
.my-link
  display: inline-block
  width: 100%
  overflow: hidden
  text-overflow: ellipsis

.my-list
  margin-left: 0rem
  margin-right: 0rem

.myavatar
  position: absolute
  left: 50%
  margin-left: -100px
  bottom: -100px
  img
    border: 10px solid white

.myicon
  background-color: lightgrey

.myname
  margin-top: 120px
  margin-bottom: 0px
</style>

<script>
import { useRoute } from "vue-router";
import axios from "axios";
import { provide, ref, onBeforeMount } from "vue";
import { supabase as s } from "../supabase";
import {
  APIURL,
  next_tid,
  next_token_id,
  chainscan,
  get_member_level,
  levels,
  share_to,
} from "../web3";
import { useQuasar } from "quasar";
// import Discover from "./Discover.vue";
export default {
  // components: { Discover },
  name: "MyShop",
  setup() {
    const mycreations = ref([]);
    const profile = ref({});
    const theuser = ref({});
    const is_allow_mint = ref(false);
    const user = ref(s.auth.user());
    const share_url = ref("");
    const ulevel = ref(0);
    onBeforeMount(async () => {
      const uid = useRoute().params.uid;
      console.log(uid);
      {
        // set refer to local storage, send to server when user signin
        // if local has already has a referer or currently exist login user don't need set referer
        // only set referer for the clean new user first visit
        if (!localStorage.getItem("referer") && !user.value) {
          // query refer user info
          if (useRoute().query.rid == undefined) {
            console.log("No referer rid, use current profile user as rid");

            let { data: u, error } = await s
              .from("users")
              .select("*")
              .eq("id", uid);
            if (error) console.log(error);
            console.log(u);
            theuser.value = u[0];
            console.log(theuser.value);
            if (!localStorage.getItem("referer") && !user.value) {
              localStorage.setItem("referer", theuser.value.id);
              console.log("set local referer id:", theuser.value.id);
            }
          } else {
            const rid = useRoute().query.rid;
            console.log("referer uid", rid);
            let { data: u, error } = await s
              .from("users")
              .select("*")
              .eq("id", rid);
            if (error) console.log(error);
            else {
              console.log("Query refer user:", u[0]);
              localStorage.setItem("referer", u[0].id);
              console.log("set local referer id:", u[0].id);
            }
          }
        }
      }

      {
        let { data: u, error } = await s
          .from("users")
          .select("*")
          .eq("id", uid);
        if (error) console.log(error);
        console.log(u);
        theuser.value = u[0];
        console.log(theuser.value);

        if (!localStorage.getItem("referer") && !user.value) {
          localStorage.setItem("referer", theuser.value.id);
          console.log("set local referer id:", theuser.value.id);
        }
      }

      {
        let { data: p, error } = await s
          .from("profiles")
          .select("*")
          .eq("id", theuser.value.id);
        if (error) console.log(error);

        console.log(p[0]);
        profile.value = p[0];

        // ulevel.value = await get_member_level(
        //   theuser.value.raw_user_meta_data.wallet
        // );

        if (user.value) {
          share_url.value =
            "https://nft.ezverse.io/shop/" + p[0].id + "?rid=" + user.value.id;
        }

        // check if show the mint button
        const cur_user = s.auth.user();
        if (cur_user) {
          is_allow_mint.value =
            profile.value.utype == "seller" && theuser.value.id == cur_user.id;
        }
      }

      if (is_allow_mint.value) {
        let { data: nfts, error } = await s
          .from("v_nfts")
          .select("*")
          .eq("owner", theuser.value.raw_user_meta_data.wallet)
          .gt("flag", -2000)
          .limit(128)
          .order("created_at", { ascending: false });
        if (error) console.log(error);
        console.log(nfts);
        mycreations.value = nfts;
      } else {
        let { data: nfts, error } = await s
          .from("v_nfts")
          .select("*")
          .eq("owner", theuser.value.raw_user_meta_data.wallet)
          .eq("listing", true)
          .gt("flag", -2000)
          .limit(128)
          .order("created_at", { ascending: false });
        if (error) console.log(error);
        console.log(nfts);
        mycreations.value = nfts;
      }
    });

    return {
      chainscan,
      cur_nft: ref(),
      address: ref(""),
      prompt: ref(false),
      q: useQuasar(),
      tid: ref(""),
      tokenid: ref(0),
      claiming: ref(false),
      is_allow_mint,
      theuser,
      mycreations,
      profile,
      user,
      share_url,
      share_to,
      levels,
      ulevel,
    };
  },

  methods: {
    check_role() {
      //check balance
      if (this.is_allow_mint) {
        this.$router.push("/mint");
      } else {
        alert("Not allow mint");
        console.log("Not allow mint");
      }
    },
    async set_listing(k) {
      console.log(k);
      let data = await s
        .from("nfts")
        .update({ listing: k.listing })
        .match({ id: k.id });
      if (data["error"]) {
        console.log(data["error"]);
      } else {
        console.log(data);
        this.q.notify("Set NFT " + k.data.name + " listing status success!");
      }
    },

    join() {
      console.log("call join");
      console.log("src:", window.location.pathname);
      this.$router.push({
        path: "/login",
        query: {
          src: window.location.pathname,
        },
      });
    },
    
    async mint_token(nft) {
      this.q.loading.show();
      const url = APIURL + "/i/mint_token";

      const mintto = this.address;
      this.tid = next_tid();
      this.tokenid = await next_token_id(nft.id);
      const params = {
        to: mintto,
        contract: nft.id,
        amount: 1,
        tid: this.tid,
        tokenid: this.tokenid,
        uuid: this.user.id,
      };
      const options = {
        method: "POST",
        headers: {
          "content-type": "application/json",
        },
        data: params,
        url,
      };
      console.log("mint req:", options);
      axios(options)
        .then(async (res) => {
          console.log("mint api response:", res);
          if (res.data.blockHash != undefined) {
            this.receipt = res.data;
            console.log(this.receipt);
            // await s.transaction(async (tx) => {
            {
              const { data, error } = await s.from("tokens").insert([
                {
                  owner: mintto,
                  balance: 1,
                  data: nft.data,
                  tokenid: this.tokenid,
                  tid: this.tid,
                  contract: nft.id,
                },
              ]);
              if (error) {
                console.log(error);
              } else {
                console.log(data);
              }
            }

            {
              // Insert TXN record
              const record = {
                ufrom: this.user.id,
                contract: nft.id,
                tokenid: this.tokenid,
                tid: this.tid,
                from: "NULL",
                to: mintto,
                type: "MINT",
                price: nft.data.price,
                status: "DONE",
                txn: res,
              };
              const { data, error } = await s.from("txns").insert([record]);

              if (error) {
                console.log(error);
              } else console.log(data);
            }
          } else {
            console.log("ERR:blockchain transaction");
          }
        })
        .then(() => {
          this.q.loading.hide();
          this.q.notify(
            "Mint 1 Token of " +
              nft.data.name +
              " to " +
              this.address +
              " SUCCESS!"
          );
        });
    },
    show_nft(id) {
      this.$router.push("/nft/" + id);
    },
    pdate(dstr) {
      const date = new Date(dstr);
      return new Intl.DateTimeFormat("default", { dateStyle: "long" }).format(
        date
      );
    },
    async set_level(level) {
      console.log("set user level:", level);
      const { data, error } = await s
        .from("profiles")
        .update({ ulevel: level })
        .match({ id: this.theuser.id });
      if (error) {
        console.log(error);
      } else {
        console.log(data);
        this.profile = data[0];
      }
    },
  },
};
</script>